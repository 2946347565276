// @ts-nocheck
import IconButton from "material-ui/IconButton";
import ActionDateRange from "material-ui/svg-icons/action/date-range";
import DeviceAccessTime from "material-ui/svg-icons/device/access-time";
import IconMenu from "material-ui/IconMenu";
import SocialPerson from "material-ui/svg-icons/social/person";
import MenuItem from "material-ui/MenuItem";
import { Link } from "react-router-dom";
import ActionDoneAll from "material-ui/svg-icons/action/done-all";
import ActionDone from "material-ui/svg-icons/action/done";
import ActionDeleteForever from "material-ui/svg-icons/action/delete-forever";
import ActionOpenWith from "material-ui/svg-icons/action/open-with";
import React, { useMemo } from "react";
import SocialMoodSentimentVerySatisfied from "material-ui/svg-icons/social/sentiment-very-satisfied";
import EditorAttachMoney from "material-ui/svg-icons/editor/attach-money";
import EditorMonetizationOn from "material-ui/svg-icons/editor/monetization-on";
import { EditProductsModalButton } from "./product";
import { DisplayOutputModalButton } from "./output";
import StaticConfig from "../../../../../services/static-config";

export default ({
  bluebeamFiles,
  canReSchedule,
  isAccounts,
  isSales,
  isAdmin,
  iconButtonStyle,
  iconButtonSVGStyle,
  iconButtonTooltipStyle,
  applicators,
  visit,
  job,
  onDeleteVisit,
  onEditVisitArea,
  onPickDate,
  onPickTime,
  onAssignVisit,
  onApproveSchedule,
  onApprovedStatus,
  onEditVisitInvoice,
  visits,
  onVisitUpdate,
}) => {
  return (
    <div
      className="visit-controls"
      onClick={(event) => event.stopPropagation()}
    >
      {useMemo(
        () =>
          canReSchedule && onPickDate && !(isAccounts || isSales) ? (
            <IconButton
              title="Schedule"
              alt="Schedule"
              tooltip="Schedule"
              tooltipPosition="bottom-center"
              style={iconButtonStyle}
              iconStyle={Object.assign({}, iconButtonSVGStyle, {
                fill: "#000",
              })}
              tooltipStyles={iconButtonTooltipStyle}
              onClick={onPickDate}
            >
              <ActionDateRange />
            </IconButton>
          ) : null,
        [
          canReSchedule,
          isAccounts,
          isSales,
          iconButtonStyle,
          iconButtonSVGStyle,
          iconButtonTooltipStyle,
          onPickDate,
        ]
      )}
      {useMemo(
        () =>
          canReSchedule && onPickTime && !(isAccounts || isSales) ? (
            <IconButton
              title="Select Time"
              alt="Select Time"
              tooltip="Select Time"
              tooltipPosition="bottom-center"
              style={iconButtonStyle}
              iconStyle={Object.assign({}, iconButtonSVGStyle, {
                fill: "#000",
              })}
              tooltipStyles={iconButtonTooltipStyle}
              onClick={onPickTime}
            >
              <DeviceAccessTime />
            </IconButton>
          ) : null,
        [
          canReSchedule,
          isAccounts,
          isSales,
          iconButtonStyle,
          iconButtonSVGStyle,
          iconButtonTooltipStyle,
          onPickTime,
        ]
      )}
      {useMemo(
        () =>
          canReSchedule && isAdmin ? (
            <IconMenu
              className="hideable-small"
              iconButtonElement={
                <IconButton
                  tooltip="Assign Applicator"
                  title="Assign Applicator"
                  alt="Assign Applicator"
                  tooltipPosition="bottom-center"
                  style={iconButtonStyle}
                  iconStyle={Object.assign({}, iconButtonSVGStyle, {
                    fill: "#000",
                  })}
                  tooltipStyles={iconButtonTooltipStyle}
                >
                  <SocialPerson />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}
              onClick={(event) => event.stopPropagation()}
            >
              {applicators
                .filter((a) => a.enabled)
                .map((currentApplicator, index) => (
                  <MenuItem
                    key={index}
                    primaryText={
                      currentApplicator.name || currentApplicator.username
                    }
                    leftIcon={
                      <SocialPerson
                        style={{
                          fill:
                            currentApplicator.config &&
                            currentApplicator.config.color,
                        }}
                      />
                    }
                    onClick={(event) => {
                      if (event && event.stopPropagation) {
                        event.stopPropagation();
                      }
                      onAssignVisit({ visit, job }, currentApplicator);
                    }}
                  />
                ))}
            </IconMenu>
          ) : null,
        [
          visit,
          job,
          canReSchedule,
          isAdmin,
          applicators,
          iconButtonStyle,
          iconButtonSVGStyle,
          iconButtonTooltipStyle,
          onAssignVisit,
        ]
      )}
      {useMemo(
        () =>
          !(isSales || isAccounts) ? (
            <Link
              to={`/job/visit/${visit._id.$oid}`}
              className="hideable-small"
            >
              <IconButton
                title={visit.submission ? "View Visit" : "Complete Visit"}
                tooltipPosition="bottom-center"
                tooltip={visit.submission ? "View Visit" : "Complete Visit"}
                style={iconButtonStyle}
                iconStyle={Object.assign({}, iconButtonSVGStyle, {
                  fill: "#000",
                })}
                tooltipStyles={iconButtonTooltipStyle}
              >
                {visit.submission ? <ActionDoneAll /> : <ActionDone />}
              </IconButton>
            </Link>
          ) : null,
        [
          isSales,
          isAccounts,
          visit._id.$oid,
          visit.submission,
          iconButtonStyle,
          iconButtonSVGStyle,
          iconButtonTooltipStyle,
        ]
      )}
      {useMemo(
        () =>
          canReSchedule && isAdmin && onDeleteVisit instanceof Function ? (
            <IconButton
              title="Delete Site Visit"
              alt="Delete Site Visit"
              className="hideable-small"
              tooltip="Delete Site Visit"
              tooltipPosition="bottom-center"
              style={iconButtonStyle}
              iconStyle={Object.assign({}, iconButtonSVGStyle, {
                fill: "#000",
              })}
              tooltipStyles={iconButtonTooltipStyle}
              onClick={onDeleteVisit}
            >
              <ActionDeleteForever />
            </IconButton>
          ) : null,
        [canReSchedule, isAccounts, onDeleteVisit]
      )}
      {useMemo(
        () =>
          isAdmin &&
          visit.hasOwnProperty("scheduleChangeApprovedByAdmin") &&
          !visit.scheduleChangeApprovedByAdmin &&
          onApproveSchedule instanceof Function ? (
            <IconButton
              title="Approve Schedule"
              alt="Approve Schedule"
              tooltip="Approve Schedule"
              tooltipPosition="bottom-center"
              style={iconButtonStyle}
              iconStyle={Object.assign({}, iconButtonSVGStyle, {
                fill: "#ff4081",
              })}
              tooltipStyles={iconButtonTooltipStyle}
              onClick={onApproveSchedule}
            >
              <SocialMoodSentimentVerySatisfied />
            </IconButton>
          ) : undefined,
        [
          isAdmin,
          visit.scheduleChangeApprovedByAdmin,
          onApproveSchedule,
          iconButtonStyle,
          iconButtonSVGStyle,
          iconButtonTooltipStyle,
        ]
      )}
      {useMemo(() => {
        if (isAdmin && visit.status === "Applied" && onApprovedStatus) {
          return (
            <IconButton
              title="Approve for invoicing"
              alt="Approve for invoicing"
              tooltip="Approve for invoicing"
              tooltipPosition="bottom-center"
              style={iconButtonStyle}
              iconStyle={Object.assign({}, iconButtonSVGStyle, {
                fill: "#ff4081",
              })}
              tooltipStyles={iconButtonTooltipStyle}
              onClick={onApprovedStatus}
            >
              <EditorAttachMoney />
            </IconButton>
          );
        }

        if (
          onEditVisitInvoice &&
          (isAdmin || isAccounts) &&
          visit.status === "Approved" &&
          onVisitUpdate &&
          visits &&
          visits.length
        ) {
          const additionalCompleteStatusStyle = {};

          if (isAccounts) {
            additionalCompleteStatusStyle.fill = "#ff4081";
          }

          return (
            <IconButton
              title="Complete"
              alt="Complete"
              tooltipPosition="bottom-center"
              style={iconButtonStyle}
              iconStyle={Object.assign(
                {},
                iconButtonSVGStyle,
                additionalCompleteStatusStyle
              )}
              tooltipStyles={iconButtonTooltipStyle}
              onClick={onEditVisitInvoice}
            >
              <ActionDone />
            </IconButton>
          );
        }

        return undefined;
      }, [
        isAdmin,
        isAccounts,
        visit.status,
        onEditVisitInvoice,
        onApprovedStatus,
        iconButtonStyle,
        iconButtonSVGStyle,
        iconButtonTooltipStyle,
        onVisitUpdate && visits ? visits.length : undefined,
      ])}
      {useMemo(
        () =>
          (isAdmin || isAccounts) &&
          visit.status === "Complete" &&
          visits &&
          visits.length &&
          onEditVisitInvoice ? (
            <IconButton
              title="Invoice Details"
              alt="Invoice Details"
              tooltipPosition="bottom-center"
              style={iconButtonStyle}
              iconStyle={iconButtonSVGStyle}
              tooltipStyles={iconButtonTooltipStyle}
              onClick={onEditVisitInvoice}
            >
              <EditorMonetizationOn />
            </IconButton>
          ) : undefined,
        [
          isAdmin,
          isAccounts,
          visits ? visits.length : undefined,
          iconButtonStyle,
          iconButtonSVGStyle,
          iconButtonTooltipStyle,
          onEditVisitInvoice,
        ]
      )}
      {useMemo(() => {
        if (!(job && isAdmin && onEditVisitArea)) {
          return undefined;
        }

        const areaIconStyle = {};

        if (
          isAdmin &&
          !(visit.area && visit.area.length >= job.products.length)
        ) {
          areaIconStyle.fill = "#ff4081";
        }

        return (
          <IconButton
            title="Update Area"
            alt="Update Area"
            className="hideable-small"
            tooltipPosition="bottom-center"
            style={iconButtonStyle}
            iconStyle={Object.assign({}, iconButtonSVGStyle, areaIconStyle)}
            tooltipStyles={iconButtonTooltipStyle}
            onClick={onEditVisitArea}
          >
            <ActionOpenWith />
          </IconButton>
        );
      }, [
        isAdmin,
        visit.area ? visit.area.length : undefined,
        job ? undefined : job.products.length,
        iconButtonStyle,
        iconButtonSVGStyle,
        iconButtonTooltipStyle,
        onEditVisitArea,
      ])}
      {useMemo(() => {
        if (
          (visit && !isAdmin) ||
          visit.status !== "Pending" ||
          !StaticConfig.flags.APPLICATOR_APP
        ) {
          return undefined;
        }
        return (
          <EditProductsModalButton
            visit={visit}
            iconButtonStyle={iconButtonStyle}
            iconButtonSVGStyle={iconButtonSVGStyle}
            iconButtonTooltipStyle={iconButtonTooltipStyle}
            bluebeamFiles={bluebeamFiles}
            job={job}
            applicators={applicators}
            onVisitUpdate={onVisitUpdate}
          />
        );
      }, [
        onVisitUpdate,
        applicators,
        bluebeamFiles,
        job,
        isAdmin,
        iconButtonStyle,
        visit,
        iconButtonSVGStyle,
        iconButtonTooltipStyle,
      ])}
      {useMemo(() => {
        if ((visit && !isAdmin) || !StaticConfig.flags.APPLICATOR_APP) {
          return undefined;
        }
        return (
          <DisplayOutputModalButton
            visit={visit}
            iconButtonStyle={iconButtonStyle}
            iconButtonSVGStyle={iconButtonSVGStyle}
            iconButtonTooltipStyle={iconButtonTooltipStyle}
            job={job}
            applicators={applicators}
            onVisitUpdate={onVisitUpdate}
          />
        );
      }, [
        onVisitUpdate,
        applicators,
        job,
        isAdmin,
        iconButtonStyle,
        visit,
        iconButtonSVGStyle,
        iconButtonTooltipStyle,
      ])}
    </div>
  );
};
